/**
 * @author Nils von Rymon-Lipinski
 * VIEWPORTS MSRC
 * mobile: (max-width: 479px) --> mobile
 * tabletAndUp: (min-width: 480px)
 * tablet: (min-width: 480px) and (max-width: 768px) --> tablet
 * tabletAndDown: (max-width: 767px)
 * tabletWideAndUp: (min-width: 768px)
 * tabletWide: (min-width: 768px) and (max-width: 991px) --> tablet-wide
 * tabletWideAndDown: (max-width: 991px)
 * desktop: (min-width: 992px) and (max-width: 1199px) --> desktop
 * desktopAndDown: (max-width: 1199px)
 * desktopAndUp: (min-width: 992px)
 * largeDesktop: (min-width: 1200px) --> desktop-large
 */

/**
 * Store GlobalSetup
 */
ui.globalSetup = ui.getElementSetup({ el: $('html')[0] });
ui.globalSetup.theme = 'mgb';
ui.globalSetup.colCount = ['2', '3', '3', '4', '4']; // mobile, tablet, tablet-wide, desktop, desktop-large.
ui.globalSetup.tilesVisibleCount = ['1.5', '2.5', '3', '4', '4']; // mobile, tablet, tablet-wide, desktop, desktop-large.
ui.globalSetup.paginationOptions = {
  disabled: true,
};

ui.getSetup = (data) => {
  let dataTemp;
  try {
    dataTemp = JSON.parse(data.replace(/'/g, '"'));
  } catch (error) {
    console.error('ERROR >> getElementSetup', 'dataSetup', data);
    console.error('ERROR >> getElementSetup', 'error :', error);
  }
  return dataTemp;
};

/**
 * return user data object
 * @param user {obj}
 * @returns {identifier, name, token}
 */
ui.getUserObj = (user) => {
  console.log('getUserObj', 'data', user);
  const authority =
    ui.globalSetup.env === 'test' ? 'https://test-login.migros.ch' : 'https://login.migros.ch';
  const tokenIdentifier = `oidc.user:${authority}:${ui.globalSetup.oidcClientId}`;
  const identifier = user.sub;
  const name = user.nickname;
  const token = ui.getSetup(sessionStorage[tokenIdentifier]).access_token;
  return { identifier, name, token };
};

ui.setUserObj = (identifier, name, token) => {
  return { identifier, name, token };
};

ui.getLanguage = function (options) {
  options.language = options.language || ui.globalSetup.lang;
  return options;
};

ui.getBasicOptions = function (options) {
  options.environment = options.environment || ui.globalSetup.env;
  options.env = options.environment || ui.globalSetup.env;
  options.webAPIKey = options.webAPIKey || ui.globalSetup.sessionKey;
  options.theme = options.theme || ui.globalSetup.theme;
  options.trackingInfo = options.trackingInfo || 'products';
  ui.getLanguage(options);
  return options;
};

ui.uuid = function (prefix) {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return prefix + '_' + Math.random().toString(36).substr(2, 9);
};
