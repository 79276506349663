/**
/**
 * @modified by Nils von Rymon-Lipinski
 * Liip/Smartive responsive image script init
 * @param {*} [context] Selector string or DOM element or undefined for using global
 */
ui.bootstrapVendorUnveilImagesWidget = function (context) {
  if ($.fn.unveil) {
    if (!context) {
      // don't load images when has parent with class .js-no-initial-bootstrap-bootstrap
      // the .js-no-initial-bootstrap is used when contents are loaded for SEO and afterwards
      // overloaded by AJAX for random products
      $('.mui-js-unveil-image:not(.js-no-initial-bootstrap .mui-js-unveil-image)').unveil();
    } else {
      // AJAX loaded HTML has always a context, thus the condition for AJAX are met
      $('.mui-js-unveil-image', context).unveil();
    }
  }
};
