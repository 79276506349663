/**
 * @modified by Nils von Rymon-Lipinski
 * ALWAYS USE the prefix bootstrapVendor[ComponentName] for vendor component initialization methods!
 * This is processed later one!
 *
 * msrc.components.login
 * msrc.components.search.box
 * msrc.components.search.results (per FreeHTML)
 * msrc.components.community.tryvertising (per FreeHTML)  → tryvertising-msrc.hbs
 * msrc.components.newsletter.newsletterSignUp (per FreeHTML) → newsletter-signup-msrc.hbs
 *
 * namespace is window.msrc
 *
 * example : window.msrc.components.login(selector, options);
 *
 * This is not included in header-footer.js packed JS file
 */
(function () {
  /**
   * init widgets or not
   */
  let initUserWidgets = true;
  let wasAutologin = false;
  const logLogin = localStorage.getItem('logLogin') === 'true';

  /**
   * Liip/Smartive login widget
   * @param {*} [context] Selector string or DOM element or undefined for using global
   */
  ui.bootstrapVendorLoginWidget = function (context) {
    if (!wasAutologin) {
      wasAutologin = true;
      (async () => {
        logLogin &&
          console.log(
            'setup and autologin: ',
            JSON.stringify(JSON.parse(document.documentElement.dataset.setup))
          );
        await window.msrc.utilities.login.setup(JSON.parse(document.documentElement.dataset.setup));
      })();
    }

    const initLogin = (el, options) => {
      el.id = ui.uuid('Login');
      options = ui.getLanguage(options);
      window.msrc.components.login.button('#' + el.id, options);
    };

    if (
      !!initUserWidgets &&
      window.msrc &&
      window.msrc.components &&
      window.msrc.components.login
    ) {
      if (window.msrc.components.login.button) {
        var $loginButtons = $('.ui-js-login', context);
        $loginButtons.each(function (i, loginButton) {
          if (loginButton.dataset.setup) {
            initLogin(loginButton, JSON.parse(loginButton.dataset.setup));
          } else {
            initLogin(loginButton, {});
          }
        });
      }

      if (window.msrc.components.login.profileFlyout) {
        var $profil = $('.ui-js-profile', context);
        $profil.each(function (i, profileFlyout) {
          let options = profileFlyout.dataset.setup ? JSON.parse(profileFlyout.dataset.setup) : {};
          options = ui.getLanguage(options);
          window.msrc.components.login.profileFlyout(profileFlyout, options);
        });
      }
    }
  };

  /**
   * Liip/Smartive login widget
   * @param {*} [context] Selector string or DOM element or undefined for using global
   */
  ui.bootstrapVendorSearchWidget = function (context) {
    if (
      window.msrc &&
      window.msrc.components &&
      window.msrc.components.search &&
      window.msrc.components.search.box
    ) {
      var $widget = $('.mui-js-search', context);
      var setup = ui.getElementSetup({ el: $widget[0] });
      if ($widget.length && setup && setup.config) {
        if (setup.config && setup.config.opensOnSubmitInSameWindow === undefined) {
          setup.config.opensOnSubmitInSameWindow = true;
        }

        const options = {
          env: setup.env,
          client: setup.client || ui.globalSetup.theme,
          language: setup.language || ui.globalSetup.lang,
          config: setup.config,
        };

        window.msrc.components.search.box('.mui-js-search', options);
      }
    }
  };

  /**
   * Liip/Smartive search box component init
   * @param {*} [context] Selector string or DOM element or undefined for using global
   */
  ui.bootstrapVendorSearchResultComponent = function (context) {
    if (
      window.msrc &&
      window.msrc.components &&
      window.msrc.components.search &&
      window.msrc.components.search.results
    ) {
      var $widget = $('.mui-js-search-results', context);
      var setup = ui.getElementSetup({ el: $widget[0] });

      if ($widget.length && setup) {
        const options = {
          ...setup,
          client: setup.client || ui.globalSetup.theme,
          language: setup.language || ui.globalSetup.lang,
        };

        window.msrc.components.search.results('.mui-js-search-results', options);
      }
    }
  };

  ui.boostrapVendorNewsletterSignUp = async function () {
    if (
      !initUserWidgets ||
      !window.msrc ||
      !window.msrc.utilities ||
      !window.msrc.utilities.login ||
      !window.msrc.utilities.login.login ||
      !window.msrc.components ||
      !window.msrc.components.newsletter ||
      !window.msrc.components.newsletter.newsletterSignUp
    )
      return;

    const user = await window.msrc.utilities.login.getUser();
    const accessToken = user && user.access_token ? user.access_token : '';

    const $container = $('.mui-js-newsletter-signup');
    const signUpAction = $container.data('signup-action-text') || 'Jetzt abonnieren';
    const subscribeAction =
      $container.data('subscribe-action-text') || 'Migros-Newsletter Abonnieren';
    window.msrc.components.newsletter.newsletterSignUp('.mui-js-newsletter-signup', {
      authToken: accessToken,
      webAPIKey: ui.globalSetup.sessionKey,
      env: ui.globalSetup.env,
      language: ui.globalSetup.lang,
      theme: 'mgb',
      newsletterId: 'BOM',
      platformId: 'migros-ch',
      login: window.msrc.utilities.login.login,
      translations: {
        signUpAction,
        subscribeAction,
      },
    });
  };

  ui.boostrapVendorTryvertising = async function () {
    if (
      !initUserWidgets ||
      !window.msrc ||
      !window.msrc.utilities ||
      !window.msrc.utilities.login ||
      !window.msrc.utilities.login.login ||
      !window.msrc.components ||
      !window.msrc.components.community ||
      !window.msrc.components.community.tryvertising
    )
      return;

    const user = await window.msrc.utilities.login.getUser();
    const accessToken = user && user.access_token ? user.access_token : '';

    const $container = $('.mui-js-tryvertising');
    const tryvertisingSlug = $container.data('tryvertising-slug');

    if (!tryvertisingSlug) return;

    window.msrc.components.community.tryvertising('.mui-js-tryvertising', {
      env: ui.globalSetup.env,
      theme: 'mgb',
      language: ui.globalSetup.lang,
      tryvertisingSlug,
      login: window.msrc.utilities.login.login,
      userToken: accessToken,
      webAPIKey: ui.globalSetup.sessionKey,
    });
  };

  var $uiScope;

  /**
   * Checks widget initialize scope.
   * Should be on migros.ch the whole document (null).
   * Only on third party pages the header fragment element (.ui-scope .ui-js-header-panel) exists.
   *
   * @returns {*|jQuery|HTMLElement}
   */
  function getScope() {
    var $uiScope = $('.ui-scope .ui-js-header-panel');

    if ($uiScope.length === 0) {
      $uiScope = null;
    }

    return $uiScope;
  }

  /**
   * components which requires window load
   */
  $(function () {
    initUserWidgets = $('.prevent-vendor-init').length === 0;
    $uiScope = getScope();
    // this component cannot be in fragments requested via AJAX, so
    // prevent from reinitialization
    ui.bootstrapVendorLoginWidget.reinitializable = false;

    ui.bootstrapVendorLoginWidget($uiScope); // MSRC
    ui.bootstrapVendorSearchWidget($uiScope); // MSRC

    ui.bootstrapVendorSearchResultComponent($uiScope);
    ui.boostrapVendorNewsletterSignUp($uiScope);
    ui.boostrapVendorTryvertising($uiScope);
  });

  /**
   * init components which requires window load
   */
  $(window).on('load', function () {
    // don't give context on load, see bootstrapVendorUnveilImagesWidget
    ui.bootstrapVendorUnveilImagesWidget($uiScope);
    ui.bootstrapVendorProductsConfigurationWidgets($uiScope); // MSRC
  });

  /**
   * init vendor components after ajax
   * @param context - current target for ajax html replace
   */
  ui.reinitbootstrapVendorWidgets = function (context) {
    ui.bootstrapVendorProductsConfigurationWidgets(context); // MSRC
  };
}).call(this);
