ui.bootstrapVendorProductsConfigurationWidgets = function (context) {
  function eachWidget($widgets, widgetName, discount, callbackFunction) {
    if ($widgets.length === 0) {
      return false;
    }

    if (localStorage.getItem('logMsrcWidgets') === 'true') {
      console.log('FOUND MSRC', widgetName, $widgets.length);
    }

    $widgets.each(function (i, el) {
      var $el = $(el);
      var setup = ui.getElementSetup({ el });

      if (setup && setup.widgetConfig) {
        var widgetConfig =
          setup.widgetConfig instanceof Array ? setup.widgetConfig : [setup.widgetConfig];

        widgetConfig.forEach(function (obj, idx) {
          var options = obj;
          var $inAccordion = $el.parents('.js-tabs-overflow-control');
          var $inTab = $el.parents('.js-tabs-fit-control');

          // as long as the shoppinglist is removed from msc build, this parameter must be disabled
          options.hideAddToShoppingList = true;

          if (options.mobilSlider && document.body.clientWidth < 992) {
            widgetName = discount ? 'OfferSlider' : 'ProductSlider';
            if (
              options.filterOptions &&
              options.filterOptions.additionalQueryParams &&
              options.filterOptions.additionalQueryParams.limit
            ) {
              options.limit = options.filterOptions.additionalQueryParams.limit;
            }
            callbackFunction = ui.initProductSliderOrOfferSlider;
          }

          if ($inAccordion.length) {
            if (!!$inAccordion[0].hidden === false) {
              if ($el.parents('.panel-collapse.collapse.in').length > 0) {
                callbackFunction(el, options, discount, widgetName);
              }
            }
            $inAccordion.on('shown.bs.collapse', function (e) {
              if ($el.parents('.panel-collapse.collapse.in').length > 0) {
                callbackFunction(el, options, discount, widgetName);
              }
            });
          } else if ($inTab.length) {
            if (!!$inTab[0].hidden === false) {
              if ($el.parents('.tab-pane.active').length > 0) {
                callbackFunction(el, options, discount, widgetName);
              }
            }
            $inTab.on('shown.bs.tab', function (e) {
              if ($el.parents('.tab-pane.active').length > 0) {
                callbackFunction(el, options, discount, widgetName);
              }
            });
          } else {
            callbackFunction(el, options, discount, widgetName);
          }
        });
      }
    });
  }

  if (window.msrc && window.msrc.components && window.msrc.components.articles) {
    if (window.msrc.components.articles.product) {
      eachWidget($('.ui-js-product', context), 'Product', false, ui.initProductOrOffer);
    }

    if (window.msrc.components.articles.offer) {
      eachWidget($('.ui-js-offer', context), 'Offer', true, ui.initProductOrOffer);
    }

    if (window.msrc.components.articles.productList) {
      eachWidget(
        $('.ui-js-product-list', context),
        'ProductList',
        false,
        ui.initProductListOrOfferList
      );
    }

    if (window.msrc.components.articles.offerlist) {
      eachWidget($('.ui-js-offer-list', context), 'OfferList', true, ui.initProductListOrOfferList);
    }

    if (window.msrc.components.articles.productSlider) {
      eachWidget(
        $('.ui-js-product-slider', context),
        'ProductSlider',
        false,
        ui.initProductSliderOrOfferSlider
      );
    }

    if (window.msrc.components.articles.offerSlider) {
      eachWidget(
        $('.ui-js-offer-slider', context),
        'OfferSlider',
        true,
        ui.initProductSliderOrOfferSlider
      );
    }
  }
};

ui.initMsrcWidget = function (el, widgetName, options, initFunction) {
  if (el.id.length > 0) {
    return;
  }
  const id = ui.uuid(widgetName);
  if (localStorage.getItem('logMsrcWidgets') === 'true') {
    console.log('INIT', widgetName, 'ID', id, 'OPTIONS', options);
    console.log('JSON stringify : ', JSON.stringify(options));
  }
  el.id = id;
  initFunction('#' + id, options);
};

ui.initProductOrOffer = function (el, options, discount, widgetName) {
  options = ui.getBasicOptions(options);
  if (options.index === undefined) {
    var rowAncestor = $(el).parents('.row');
    var colContainers;
    if (rowAncestor.length) {
      rowAncestor =
        $(rowAncestor[0]).hasClass('ui-js-data-layer-item') && rowAncestor.length > 1
          ? $(rowAncestor[1])
          : rowAncestor.first();
      colContainers = $(rowAncestor).children('[class*="col-"]');

      if (colContainers === undefined) {
        options.index = 0;
      } else {
        var currentColContainer = colContainers.filter(function (index) {
          return $(this).find($(el)).length === 1;
        });
        if (currentColContainer.length > 0) {
          options.index = colContainers.index(currentColContainer);
        } else {
          options.index = 0;
        }
      }
    }
  }

  if (options.layout && options.layout === 'mobil-mini') {
    if (document.body.clientWidth < 480) {
      options.layout = 'mini';
    } else {
      options.layout = 'default';
    }
  }

  if (discount) {
    ui.initMsrcWidget(el, widgetName, options, window.msrc.components.articles.offer);
  } else {
    ui.initMsrcWidget(el, widgetName, options, window.msrc.components.articles.product);
  }
};

ui.initProductListOrOfferList = function (el, options, discounts, widgetName) {
  options = ui.getBasicOptions(options);
  options.paginationOptions = options.paginationOptions || ui.globalSetup.paginationOptions;
  options.colCount = options.colCount || ui.globalSetup.colCount;

  if (options.limit) {
    options.articlesPerPage = options.limit;
  }

  if (discounts) {
    ui.initMsrcWidget(el, widgetName, options, window.msrc.components.articles.offerlist);
  } else {
    ui.initMsrcWidget(el, widgetName, options, window.msrc.components.articles.productList);
  }
};

ui.initProductSliderOrOfferSlider = function (el, options, discounts, widgetName) {
  options = ui.getBasicOptions(options);
  options.tilesVisibleCount = options.tilesVisibleCount || ui.globalSetup.tilesVisibleCount;

  if (discounts) {
    ui.initMsrcWidget(el, widgetName, options, window.msrc.components.articles.offerSlider);
  } else {
    ui.initMsrcWidget(el, widgetName, options, window.msrc.components.articles.productSlider);
  }
};
